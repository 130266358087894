import React from 'react'
import './Header.css'

function Header() {
    return (
      <header className="Header">
        <div className="Header-namespace">
          <a href="/">enewey.com</a>
        </div>
        <div className="Header-iconspace">
          <div className="Header-icon"><a href="https://github.com/enewey">github</a></div>
          <div className="Header-icon"><a href="https://linkedin.com/in/enewey">linkedin</a></div>
          <div className="Header-icon"><a href="https://twitter.com/narcodis">twitter</a></div>
        </div>
      </header>
    )
}

export default Header