import React from 'react'
import Header from './Header'
import './App.css'

function App() {
  return (
    <div className="App">
      <Header/>
      <div className="App-body">
        <div className="App-margin"></div>
        <div className="App-content">
          <div className="App-large">
            Erich Newey
          </div>
          <div className="App-small">
            Software Engineer in Salt Lake City
          </div>
          <div className="App-small">
            (more to come)
          </div>
        </div>
      </div>
    </div>
  );
}

export default App
